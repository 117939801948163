import React from 'react'
import PageHelmet from '../components/PageHelmet'

const styles = `
  .commercialEnquiries {
    max-width: 800px;
    margin: 0 auto;
  }
  .commercialEnquiries span {
    display: inline-block;
    font-weight: 500;
    min-width: 200px;
  }
  .commercialEnquiries p {
    margin-bottom: 0.5rem;
  }
  h1 {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #dee2e6;
  }
`

const PageContactUs = () => (
  <>
    <PageHelmet {...{ title: 'Contact us' }} />
    <style dangerouslySetInnerHTML={{ __html: styles }} />
    <h1>Advertising/Commercial Enquiries</h1>
    <div className="commercialEnquiries">
      <p>
        <span>By Email:</span>
        <br />
        <a href="mailto:hello@wikijob.co.uk">hello@wikijob.co.uk</a>
      </p>
      <p>
        <span>Registered Office:</span>
        <br />
        WikiJob Ltd, 251 Gray&apos;s Inn Road, London, England, WC1X 8QT
        <br />
        WikiJob Ltd is registered in England &amp; Wales, registered company
        number 06655232.
      </p>
    </div>
    <h2>Send Us a Message</h2>
    <iframe
      {...{
        title: 'Contact Us form',
        src: 'https://services.cognitoforms.com/f/9KvIt-qQt0C5BJl53afP6w?id=38',
        style: {
          position: 'relative',
          minWidth: '100%',
          width: '100%',
        },
        frameBorder: '0',
        scrolling: 'yes',
        seamless: 'seamless',
        height: '643',
        width: '100%',
      }}
    />
  </>
)

export default PageContactUs
